import React, { useCallback, useState } from "react";
import styles from "./Button.module.css";

interface PropsType {
  label: string;
  alternateLabel?: string;
  chaseMode?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  shiny?: boolean;
  name?: string;
}

const Button = ({ label, alternateLabel = undefined, onClick, name, chaseMode = false, shiny = false }: PropsType) => {
  const [id] = useState(Math.random().toString(36).substr(2, 9));
  const [active, setActive] = React.useState<boolean>(false);
  const [alternativeState, setAlternativeState] = React.useState<boolean>((alternateLabel === undefined || alternateLabel === null) ? true : false);

  React.useEffect(() => {
    const button = document.getElementById(id);
    if (!button) return;

    button.addEventListener("touchstart", () => setActive(true), { passive: true });
    button.addEventListener("mousedown", () => setActive(true), { passive: true });
    button.addEventListener("touchend", () => setActive(false), { passive: true });
    button.addEventListener("mouseup", () => setActive(false), { passive: true });
  }, [id])

  const handleAlternativeState = useCallback(() => {
    setAlternativeState(true);
    setTimeout(() => setAlternativeState(false), 3000);
  }, []);

  const handleClick = (ev: any) => {
    if (!alternateLabel && onClick) onClick(ev);

    if (alternateLabel && !alternativeState) handleAlternativeState();

    if (alternateLabel && alternativeState) {
      if (onClick) onClick(ev);
      setAlternativeState(false);
    }
  }

  React.useEffect(() => {
    if (chaseMode === false) return;
    const button = document.getElementById(id) as HTMLElement;
    if (!button) return;
    button.style.position = `fixed`;
    button.style.animationDuration = `2s`;
    button.style.transition = `all .1s linear`;

    const buttonLogic = () => {
      const size = button.getBoundingClientRect();
      button.style.top = `${Math.floor(Math.random() * (window.innerHeight - size.height))}px`;
      button.style.left = `${Math.floor(Math.random() * (window.innerWidth - size.width))}px`;
    }

    button.addEventListener("mouseover", buttonLogic, { passive: true })
    button.addEventListener("touchstart", buttonLogic, { passive: true })
  }, [chaseMode, id]);

  return (
    <button
      id={id}
      className={`${styles.Button} ${active && styles.Active} ${shiny && styles.Shiny}`}
      disabled={chaseMode}
      name={name}
      onClick={handleClick}
    >
      {alternativeState && alternateLabel ? alternateLabel : label}
    </button>
  )
}

export default Button;
