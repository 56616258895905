export const form = [
  {
    question: "Vous considérez-vous gay ?",
    answers: [
      { label: "Oui", score: 50 },
      { label: "Non", score: 20 },
      { label: "Autre", score: 1 }
    ]
  },
  {
    question: "Et maintenant ?",
    answers: [
      { label: "Toujours", score: 20 },
      { label: "J'ai changé d'avis", score: 30 }
    ]
  },
  {
    question: "C'est votre dernier mot Jean-Pierre ?",
    answers: [
      { label: "OUI !", score: 0 },
      { label: "La réponse D", score: 0 }
    ]
  },
  {
    question: "Avez-vous déjà embrassé une personne du même sexe ?",
    answers: [
      { label: "Oui", score: 30 },
      { label: "Non", score: 10 },
      { label: "J'avais bu", score: 20 }
    ]
  },
  {
    question: "Avez-vous déjà visité nohomo.fr ?",
    answers: [
      { label: "Oui", score: 10 },
      { label: "Non", score: 0, event: () => { window.open("https://nohomo.fr") } }
    ]
  },
  {
    question: "Vous vous sentez",
    answers: [
      { label: "Homme", score: 10 },
      { label: "Femme", score: 10 },
      { label: "Autre", score: 10000 }
    ]
  },
  {
    question: "Avez-vous confiance en vous ?",
    answers: [
      { label: "Oui", score: 10 },
      { label: "Non", score: 20 },
      { label: "Je crois", score: 30 }
    ]
  },
  {
    question: "",
    answers: [
      { label: "Chien", score: 20 },
      { label: "Chat", score: 30 },
    ]
  },
  {
    question: "Mariage... ?",
    answers: [
      { label: "Pour tous", score: 30 },
      { label: "Traditionnel", score: 10 },
      { label: "L'autre", score: 20, alternative: "Je suis moche", shiny: true }
    ]
  },
  {
    question: "Utilisez-vous régulièrement les filtres Snapchat ?",
    answers: [
      { label: "Oui", score: 30 },
      { label: "Non", score: 20 },
      { label: "Snap quoi ?", score: 1 }
    ]
  },
  {
    question: "Choisissez",
    answers: [
      { label: "Justin Bieber", score: 10 },
      { label: "BTS", score: 20 },
      { label: "Perdre l'audition", score: 1 }
    ]
  },
  {
    question: "L'un d'entre eux doit disparaître, lequel ?",
    answers: [
      { label: "Taylor Swift", score: 10 },
      { label: "Eminem", score: 40 },
      { label: "Rick Astley", score: 0, event: () => { window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ") } },
    ]
  },
  {
    question: "Céline Dion",
    answers: [
      { label: "Sur-côté", score: 10 },
      { label: "Sous-côté", score: 40 },
    ]
  },
  {
    question: "Au bar vous commandez",
    answers: [
      { label: "Un pastis", score: 10 },
      { label: "Un monaco", score: 30 }
    ]
  },
  {
    question: "Avez-vous hésité avant de répondre à une de ces questions ?",
    answers: [
      { label: "Oui", score: 20 },
      { label: "Non", score: 10 },
      { label: "Heuuuu...", score: 20 }
    ]
  },
]