import styles from "./Progress.module.css";

interface ProgressProps {
  value: number;
  max: number;
  className?: string;
}

const Progress = ({ value, max, className }: ProgressProps) => {
    return (
      <div className={`${styles.Progress} ${className}`}>
        <div className={styles.Bar} style={{ width: `${((value) / (max - 1)) * 100}%` }}>{value ? `${Math.round(((value) / (max - 1)) * 100)}%` : ""}</div>
      </div>
    )
}

export default Progress;
