import logo from './assets/images/gay_test_banner_logo.svg';
import styles from "./App.module.css";
import Button from './components/Button';
import { useCallback, useEffect, useState } from 'react';
import Progress from './components/Progress';
import Mailto from "react-mailto-link";

import { form } from "./assets/content/form";

interface AnswerType {
  label: string;
  score: number;
  event?: () => void;
  alternative?: string;
  shiny?: boolean;
}

function App() {
  const [score, setScore] = useState<number[] | null>(null);
  const [showResults, setShowResults] = useState<boolean>(false);

  const startQuiz = () => {
    setScore([]);
  }

  const handleClick = useCallback((ev: any) => {
    if (ev.target.name !== "-1" && score !== null && score[4] !== 10000) {
      const v = form[score?.length].answers[parseInt(ev.target.name, 10) || 0].score
      setScore(s => s
        ? [...s, v]
        : [v]);
    }
  }, [score]);

  const handleBackButton = () => {
    setScore(s => s ? s.slice(0, -1) : null);
  }

  const handleShowResults = useCallback(() => {
    setShowResults(true);
  }, [])

  const displayResults = () => {
    if (score === null) return "pour les tests qui ne marchent pas";

    const sum = score.reduce((a, b) => a + b, 0);
    if (sum % 10 === 3) return "Relou";

    const total = sum + (score[0] === form[0].answers[0].score && score[1] === form[1].answers[0].score ? 50 : 0);

    if (total >= 270) return "Gay";
    return "Hétéro";
  }

  const handleReset = () => {
    setShowResults(false);
    setScore(null);
  }

  useEffect(() => {
    if (score && score.includes(10000) && score.length < form.length) {
      setTimeout(() => {
        setScore(s => s ? [...s, 10000] : [10000]);
        handleClick({ target: { name: "-1" } });
      }, 200);
    }
    if (score?.length === form.length) {
      handleShowResults();
    }
  }, [score, handleClick, handleShowResults])

  return (
    <div className={styles.App}>
      <div className={styles.Background}>
        <div className={`${styles.Band} ${styles.Red}`} />
        <div className={`${styles.Band} ${styles.Orange}`} />
        <div className={`${styles.Band} ${styles.Yellow}`} />
        <div className={`${styles.Band} ${styles.Green}`} />
        <div className={`${styles.Band} ${styles.Blue}`} />
        <div className={`${styles.Band} ${styles.Purple}`} />
      </div>
      <main className={styles.Main}>
        <header className={styles.Header}>
          <img src={logo} className={styles.Logo} onClick={handleReset} alt="main site logo" />
        </header>
        {
          score === null && (
            <section className={styles.Content}>
              <h1 className={styles.Title}>Êtes-vous gay ?</h1>
              <h2 className={styles.Subtitle}>Enfin un questionnaire pour répondre à vos interrogations</h2>
              <br />
              <div className={styles.ButtonContainer}>
                <Button label="C'est parti !" onClick={startQuiz} chaseMode={false} />
              </div>
            </section>
          )
        }
        {
          score !== undefined && score !== null && score?.length < form.length && (
            <section className={styles.Content}>
                <Progress className={styles.Progress} value={score.length} max={form.length} />
              <div className={styles.MainLine}>{form[score.length].question}</div>
              <div className={styles.AnswersBox}>
                {
                  form[score.length] && form[score.length].answers.map((answer: AnswerType, index) => (
                    <div className={styles.ForceMargin} key={index}>
                      <Button
                        label={answer.label}
                        name={index.toString()}
                        alternateLabel={answer?.alternative}
                        onClick={answer?.event || handleClick}
                        shiny={answer?.shiny || false}
                        chaseMode={false}
                      />
                    </div>
                  ))
                }
              </div>
              <div style={{ width: "100%", height: 70, display: "flex", justifyContent: "center" }}>
                { (score !== null && score.length === 0) && (<Button label="Quitter" shiny={true} chaseMode={true} />) }
              </div>
              {score?.length >= 1 && (<div className={styles.BackButton} onClick={handleBackButton}>Retour</div>)}
            </section>
          )
        }
        {
          score && score.length >= form.length && (
            <section className={styles.Content}>
              <div className={styles.MainLine}>Félicitations</div>
              <br />
              <div className={styles.SubLine}>Vous avez terminé le questionnaire avec succès !</div>
              <div className={styles.SubLine}>Nos experts ont déterminé que vous aviez un penchant :</div>
              {
                !showResults ? (
                  <>
                    <br /><br />
                    <Button label='Découvrir le résultat' onClick={handleShowResults} />
                  </>
                ) : (
                  <>
                    <div className={styles.Results}>{displayResults()}</div>
                    <Button label='Refaire le test' onClick={handleReset} shiny={true} />
                  </>
                )
              }
            </section>
          )
        }
      </main>
      <footer className={styles.Footer}>
        <section  className={styles.Contact}>
          <p>
            Encore des questions ? Contactez nous : <Mailto email="g1doute@nohomo.fr" obfuscated={true} />
          </p>
          <p>
            Une erreur ? Ça arrive !<br /><a href="https://nohomo.fr" target="_blank" rel="noreferrer">Rendez-vous sur NoHomo.fr</a>
          </p>
        </section>
      </footer>
    </div>
  );
}

export default App;
